@font-face {
	font-family: "kfont";
	src: url('../fonts/kfont.eot');
	src: url('../fonts/kfont.eot?#iefix') format('eot'),
	url('../fonts/kfont.woff2') format('woff2'),
	url('../fonts/kfont.woff') format('woff'),
	url('../fonts/kfont.ttf') format('truetype'),
	url('../fonts/kfont.svg#kfont') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: block;
}

body .icon-arrow-bottom:before,
body .icon-arrow-left:before,
body .icon-arrow-right:before,
body .icon-arrow-top:before,
body .icon-bottom:before,
body .icon-bottom2:before,
body .icon-call:before,
body .icon-cles:before,
body .icon-devis:before,
body .icon-instagram:before,
body .icon-left:before,
body .icon-left2:before,
body .icon-linkedin:before,
body .icon-livraison:before,
body .icon-member:before,
body .icon-pinterest:before,
body .icon-right:before,
body .icon-right2:before,
body .icon-time:before,
body .icon-top:before,
body .icon-top2:before,
body .icon-twitter:before,
body .icon-whatsapp:before,
body .icon-youtube:before,
body .icon-download:before,
body .icon-quotes:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

body .icon-arrow-bottom:before { content: "\EA01" }
body .icon-arrow-left:before { content: "\EA02" }
body .icon-arrow-right:before { content: "\EA03" }
body .icon-arrow-top:before { content: "\EA04" }
body .icon-bottom:before { content: "\EA05" }
body .icon-bottom2:before { content: "\EA06" }
body .icon-call:before { content: "\EA07" }
body .icon-cles:before { content: "\EA08" }
body .icon-devis:before { content: "\EA09" }
body .icon-instagram:before { content: "\EA0A" }
body .icon-left:before { content: "\EA0B" }
body .icon-left2:before { content: "\EA0C" }
body .icon-linkedin:before { content: "\EA0D" }
body .icon-livraison:before { content: "\EA0E" }
body .icon-member:before { content: "\EA0F" }
body .icon-pinterest:before { content: "\EA10" }
body .icon-right:before { content: "\EA11" }
body .icon-right2:before { content: "\EA12" }
body .icon-time:before { content: "\EA13" }
body .icon-top:before { content: "\EA14" }
body .icon-top2:before { content: "\EA15" }
body .icon-twitter:before { content: "\EA16" }
body .icon-whatsapp:before { content: "\EA17" }
body .icon-youtube:before { content: "\EA18" }
body .icon-download:before { content: "\EA19" }
body .icon-quotes:before { content: "\EA20" }
